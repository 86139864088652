import { LitElement, html, css } from "lit-element";
import Localizable from "../../../i18n/Localizable.js";
import { } from 'eot-logo';


export class MenuBar extends Localizable(LitElement) {
    
    constructor(){
        super();
        this.opened = false;
        this.ready = false;
        this._lang = "en";
    }

    set lang(newValue) {
        this.setLocale(newValue,"/assets","MenuText.js");
        this._lang = newValue;
    }

    get lang(){
        return this._lang;
    }

    static get properties() {
        return {
            opened : { type : Boolean },
            localizationReady : { type : Boolean },
            lang : { type : String }
        };
    }
    
    render(){
        if(!this.localizationReady){
            return html``;
        }
        return html`
        <wc-menu-button @opened="${this.openMenu}" @closed="${this.closeMenu}"></wc-menu-button>
        <nav class="${this.opened ? "opened" : ""}">
            <a href="/" class="logo" @click="${this.closeMenu}"><eot-logo></eot-logo></a>
            <a @click="${this.changeLanguage}" class="flag"><img  src="/assets/interface/${this.lang === "en" ? "fr" : "en"}_flag.svg"></a>
            <div class="links">
            <a href="/${this.lang}/about" @click="${this.closeMenu}">${this.getLocalizedContent("about")}</a>
            <a @click="${this.closeMenu}" class="disabled">${this.getLocalizedContent("settings")}</a>
            <a @click="${this.closeMenu}" class="disabled">${this.getLocalizedContent("support")}</a>
            </div>
            
        </nav>
        ${
            this.opened ? html`<div class="overlay" @click="${this.closeMenu}"></div>` : ""
        }`;
    }

    openMenu(){
        this.opened = true;
	}

	closeMenu(){
        this.opened = false;
        this.shadowRoot.querySelector("wc-menu-button").open = false;
    }
    
    changeLanguage(){
        const otherlang = this.lang === "en" ? "fr" : "en";
        const event = new CustomEvent("changeLanguage",{detail :{language : otherlang}});
        this.closeMenu();
        this.dispatchEvent(event);
    }

    static get styles(){
        return css`
        :host {
            position : absolute;
            top : 0;
            left : 0;
            width: 0px;
            height : 100%;
            overflow : visible;
        }
        nav {
            position : absolute;
            display : flex;
            flex-direction : column;
            top : 0;
            height : 100%;
            box-sizing : border-box;
            left : -300px;
            z-index : 8;
            width : 300px;
            background-color : rgba(14,14,14,0.7);
            padding-top : 30px;
            padding-left : 1em;
            padding-right : 1em;
            transition : left 0.4s ease-out;
            backdrop-filter : blur(3px);
        }
        nav.opened {
            left : 0;
        }
        wc-menu-button {
			--wc-menu-button-color: white;
			position: absolute;
			z-index : 12;
			top: 15px;
			left : 15px;
			width : 26px;
			height: auto;
		}
        .logo-language {
            width : 200px;
        }
        a.logo {
            width : 200px;
            align-self : center;
        }
        a:not(.logo) {
            color : white;
            align-self : center;
            font-size : 1.2em;
            font-weight : bold;
            display: block;
            background-color :#a215be;
            width : 100%;
            border-radius : 7px;
            padding : 8px;
            text-align : center;
            transition : background-color 0.4s ease-in-out;
            margin-bottom : 8px;
            text-decoration : none;
        }
        a:not(.logo):hover, a:not(.logo):focus {
            color : white;
            text-decoration : none;
            background-color :  #e63620;
        }
        a.flag {
            justify-self : flex-end;
            cursor : pointer;
            background-color : rgba(0,0,0,0);
            width : auto;
            transition : transform 0.3s ease-out;
        }
        a.flag:hover {
            transform : scale(1.25);
            background-color : rgba(0,0,0,0);
        }
        .flag img {
            width : 30px;
            height : 20px;
            border : solid 1px white;
        }
        .overlay {
            position : absolute;
            width : 100vw;
            left : 0;
            top : 0;
            right: 0;
            bottom : 0;
            z-index : 7;
        }
        a.disabled {
            pointer-events : none;
            background-color : #696969;
        }

        @media screen and (max-height : 600px) and (min-width : 300px){
            nav {
                flex-direction : row;
                top : -100%;
                width : 100vw;
                height : 100%;
                box-sizing : border-box;
                left : 0px;
                z-index : 8;
                background-color : rgba(14,14,14,0.7);
                padding-top : 30px;
                padding-left : 1em;
                padding-right : 1em;
                transition : top 0.4s ease-out;
                align-items : center;
                justify-content: space-around;
            }
            nav.opened {
                top : 0;
            }
            .flag {
                order : 3;
            }
            a:not(.logo) {
                font-size : 0.9em;
            }
            .links {
                display : flex;
                flex-wrap : wrap;
                margin : 1em;
            }
        }
        `;
    }
}