import { LitElement, html, css } from 'lit-element';
import { } from 'wtvr-expressive-text';
import Localizable from "../../../i18n/Localizable.js";


export class NotFound extends Localizable(LitElement) {

    static get properties(){
        return {
            localizationReady : {type : Boolean}
        }
    }

    async firstUpdated(){
        await this.setLocale(this.location.params.lang,"/assets","MenuText.js");
        requestAnimationFrame(() => {
            this.shadowRoot.querySelector("wtvr-expressive-text").start();
        })
    }

    render() {
        if(!this.localizationReady){
            return html``;
        }
        return html`
        ${this.getLocalizedContent("notFound")}
        `;
        
    }

    static get styles() {
        return css`
        :host {
            height : 100%;
            width : 100%;
            display : flex;
            flex-direction : column;
            align-items : center;
            justify-content : center;
            color : white;
        }
        wtvr-expressive-text {
            text-align : center;
        }
        `;
    }
}
