import { LitElement, html, css } from 'lit-element';
import { EotLogo } from 'eot-logo';
import { Router } from "@vaadin/router";
import { } from "wc-menu-button";
import { } from "../../ui/menu-bar/fullscreen-button/fullscreen-button.js";
import { } from "../../ui/menu-bar/menu-bar.js";
import { } from "../../pages/not-found/not-found.js";

let env = "";
env = "__develop__";

export class EchoesOfTwilight extends LitElement {
	
	static get properties() {
		return {
			language : { type : String }
		}
	}

	constructor(){
		super();
		this.language = "en";
		this._setLanguage();
	}

	static sceneFilePath(fileName){
		if(env[0] === "_"){
			return `/packages/scenes/episode1/${fileName}/${fileName}.js`;
		}
		return `/scenes/${fileName}.js`;
	}

	firstUpdated(){
		super.firstUpdated();
		const outlet = this.shadowRoot.querySelector("main");
		this.router = new Router(outlet);
		this.router.setRoutes([
			{path: '/', action : () => { EchoesOfTwilight.clearTimeouts(); return this._getIntro(); }},
			{
				path: '/:lang',
				action : (context) => {this.language = context.params.lang; EchoesOfTwilight.clearTimeouts();},
				children : [
					{path : "404", component : "not-found"},
					{path : "episode1/scene1", action : async () => { await import(EchoesOfTwilight.sceneFilePath("eot-e1-s1"))}, component : "eot-e1-s1"}
				]
			},
			{
				path: "(.*)", redirect : `${this.language}/404`
			}
		]);
		this.menubar = this.shadowRoot.querySelector("menu-bar");
	}
	
	render() {
		return html`
		<main></main>
		<menu-bar lang="${this.language}" @changeLanguage="${this.changeLanguage}"></menu-bar>
		<fullscreen-button></fullscreen-button>
		`;
	}

	changeLanguage(e){
		this.language = e.detail.language;
		const currentLang = this.router.location.params.lang;
		if(currentLang){
			Router.go(this.router.location.getUrl({lang : this.language}));
		}
	}
	
	_getIntro() {
		const introView = document.createElement("div");
		introView.classList.add("intro-view");
		const logo = new EotLogo();
		introView.appendChild(logo);
		logo.setAttribute("animated","");
		setTimeout(() => {
			logo.start();
		},1000);
		setTimeout(() => {
			logo.hide();
		},5000);
		setTimeout(() => {
			Router.go(`${this.language}/episode1/scene1`);
		},8000);
		return introView;
	}

	_setLanguage() {
		const supportedLanguages = ['en','fr'];
		const language = "en";
		const recordedLang = localStorage.getItem("lang");
		if(recordedLang){
			this.language = recordedLang;
			return;
		}
		let preferedLanguages = ["en"];
		if(!navigator.languages){
			if(navigator.language){
				preferedLanguages = [navigator.language];
			}
		}
		else{
			preferedLanguages = navigator.languages;
		}
		for(let j = 0; j < preferedLanguages.length; j+=1){
			for(let i = 0; i < supportedLanguages.length; i+=1){
				if(preferedLanguages[j].indexOf(supportedLanguages[i]) === 0){
					this.language = supportedLanguages[i];
					return;
				}
			}
		}
		this.language = language;
	}

	static clearTimeouts(){
		let id = window.setTimeout(() => {}, 0);

		while (id >= 0) {
			window.clearTimeout(id);
			id -= 1;
		}
	}
	
	static get styles() {
		return [css`
		:host, main, .intro-view {
			width : 100%;
			height: 100%;
		}
		.intro-view {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.intro-view eot-logo {
			max-width : 90vh;
			width : 700px;
			max-height : 85vh;
		}
		fullscreen-button {
			position: absolute;
			z-index : 9;
			bottom: 15px;
			right : 15px;
		}
		`];
	}
	
}
