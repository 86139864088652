import { LitElement, html, css } from "lit-element";


export class FullscreenButton extends LitElement {
	
	constructor(){
		super();
		this.fullscreen = false;
	}

	static get properties(){
		return {
			fullscreen : { type : Boolean }
		};
	}

	firstUpdated(){
		const a = this.shadowRoot.querySelector("a");
		a.addEventListener("click",(e) => {
			if(!this.fullscreen){
				document.documentElement.requestFullscreen();
			}
			else{
				document.exitFullscreen();
			}
			this.fullscreen = !this.fullscreen;
			e.stopPropagation()
		});
		document.addEventListener("fullscreenchange",() => {
			this.fullscreen = document.fullscreenElement !== null;
		})
	}
	
	render(){
		const corners = ["tl","tr","bl","br"];
		const svgs = corners.map((id) => html`
		<svg id="corner-${id}" class="${id} ${this.fullscreen ? 'inverted' : ''}" viewBox="0 0 13.229166 13.229166">
			<path d="M 0,0 V 4.82613 13.22917 h 4.8261254 v -8.40304 h 8.4030416 v -4.82613 H 4.8261254 Z"/>
		</svg>
		`);
		return html`<a>${svgs}</a>`;
	}

	static get styles(){
		return css`
		:host{
			display: block;
		}
		a {
			display : grid;
			height : 16px;
			width : 16px;
			padding : 7px;
			grid-gap : 4px;
			grid-template-columns : auto auto;
			grid-template-rows : auto auto;
		}
		svg {
			transition : transform 0.3s;
		}
		svg path {
			fill : white;
			fill-opacity:1;
		}
		.tr {
			transform : scaleX(-1);
		}
		.bl {
			transform : scaleY(-1);
		}
		.br {
			transform : scale(-1,-1);
		}
		a:hover .tl{
			transform : translate(-1px,-1px);
		}
		a:hover .tr{
			transform : scaleX(-1) translate(-1px,-1px);
		}
		a:hover .bl{
			transform : scaleY(-1) translate(-1px,-1px);
		}
		a:hover .br{
			transform : scale(-1,-1) translate(-1px,-1px);
		}
		.inverted.tl {
			transform : scale(-1,-1) translate(1px,1px);
		}
		.inverted.tr {
			transform : scaleY(-1) translate(1px,1px);
		}
		.inverted.bl {
			transform : scaleX(-1) translate(1px,1px);
		}
		.inverted.br {
			transform : translate(1px,1px);
		}
		a:hover .inverted.tl{
			transform : scale(-1,-1);
		}
		a:hover .inverted.tr{
			transform : scaleY(-1);
		}
		a:hover .inverted.bl{
			transform : scaleX(-1);
		}
		a:hover .inverted.br{
			transform : scale(1,1);
		}
		`;
	}
}